import React, { Component } from 'react';

import Container from './../components/container';
import TopHeader from './../components/top-header';
import ActionLeft from './../components/actions-left';
import ActionRight from './../components/actions-right';
import Footer from './../components/footer';
import RoundBtn from './../components/buttons/round-btn';
import TopHeaderBtn from './../components/buttons/top-header-btn';
import TopHeaderTitle from './../components/text/top-header-title';

import Listing from './../components/listing/listing';
import ListingItem from './../components/listing/listing-item';
import ListingItemLink from './../components/listing/listing-item-link';
import ListingItemRow from './../components/listing/listing-item-row';
import RoundCheckbox from './../components/listing/round-checkbox';
import PlusMinus from './../components/listing/plus-minus';

import Loading from './../components/loading';
import DB from './../LocalDb';

import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {selectBoxID, selectProductID} from "../redux/actions";
import ProductModel from "../models/ProductModel";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkedList: [],

            packingList: [
                // {title:'Produkt 1',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 1},
                // {title:'Produkt 2',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 2, checked: false},
                // {title:'Produkt 3',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 3},
                // {title:'Produkt 4',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 4},
                // {title:'Produkt 5',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 5},
                // {title:'Produkt 6',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 6},
                // {title:'Produkt 7',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 7},
                // {title:'Produkt 8',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 8},
                // {title:'Produkt 9',ean: '5012223334440', quantity: '2' ,date: '2000-01-01 10:00', id: 9},
            ],
            currentPackingListBoxTitle: '',
            showLoading: false
        }
    }

    componentDidMount() {
        this.loadData();
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //
    //     if(this.props.selectedProductID ){
    //         this.props.showScreen('addProduct');
    //         console.log('ssssss',this.props.selectedProductID)
    //     }
    // }

    loadData(){
        this.props.selectProductID(null);

        this.setState({
                showLoading: true,
                checkedList: []
            },()=>
                DB.getPackingListBoxDetails(this.props.selectedPackingListID,this.props.selectedBoxID).then((item)=>{
                    this.setState({
                        packingList: item.products,
                        currentPackingListBoxTitle: item.title
                    })
                }).catch(()=>{
                    alert('Wystąpil błąd')
                }).finally(()=>{
                    this.setState({showLoading: false});
                })
        );
    }

    checkboxClick(item){
        const list = this.state.packingList;
        const checkedList = this.state.checkedList;

        for (let index in list){
            if(list[index].id === item.id){
                list[index].checked = list[index].checked?false:true;

                const offset = checkedList.indexOf(item.id);
                //dodaj
                if(list[index].checked){
                    if(offset===-1){
                        checkedList.push(item.id);
                    }
                } else { // usun
                    if(offset!==-1){
                        checkedList.splice(offset)
                    }
                }
            }
        }

        this.setState({packingList: list, checkedList: checkedList});
    }

    deleteSelectedClick(){
        const list = this.state.packingList;
        const checkedList = this.state.checkedList;

        const newList = list.filter((item)=>{

            const offset = checkedList.indexOf(item.id);

            if(offset===-1)
                return item;
        });

        DB.deleteSelectedProducts(checkedList,this.props.selectedPackingListID,this.props.selectedBoxID,()=>{
            this.loadData();
        });
    }

    goBack(){
        this.props.showScreen('packingListDetails');
    }

    goToEditScreen(){
        this.props.showScreen('addPackingListDetails');
    }

    editProduct(item){
        this.props.selectProductID(item.id);
        this.props.showScreen('addProduct');
    }

    addNewProduct(){
        this.props.showScreen('addProduct');
    }

    updateQuantity(item,value){
        console.log(item,value)

        const fields = item;
        fields.quantity = value;

        const model = new ProductModel(fields);

        DB.saveProduct(model,this.props.selectedPackingListID,this.props.selectedBoxID,model.id).then(()=>{
            console.log('aktualizacja')
        }).catch((err)=>{
            console.log(err);
        });

    }

    renderDeleteBtn(){
        const checkedList = this.state.checkedList;
        if(!checkedList.length)
            return false;

        return (
            <React.Fragment>
                <RoundBtn icon="delete" click={this.deleteSelectedClick.bind(this)}/>
            </React.Fragment>
        )
    }

    renderLoading(){
        if(this.state.showLoading)
            return <Loading/>;
        return '';
    }


    render(){

        const DELETE_BTN = this.renderDeleteBtn();

        const LOADING = this.renderLoading();

        return (
            <React.Fragment>
                {LOADING}
                <TopHeader>
                    <ActionLeft>
                        <TopHeaderBtn icon="back" click={this.goBack.bind(this)} />
                        <TopHeaderTitle>{this.state.currentPackingListBoxTitle}</TopHeaderTitle>
                    </ActionLeft>
                    <ActionRight>
                        <TopHeaderBtn icon="edit" click={this.goToEditScreen.bind(this)} />
                    </ActionRight>
                </TopHeader>

                <Container role="main">
                    <Listing>
                        {this.state.packingList.sort((a,b)=>{
                            const boxA = parseInt(a.ean);
                            const boxB = parseInt(b.ean);

                            let comparison = 0;
                            if (boxA > boxB) {
                                comparison = 1;
                            } else if (boxA < boxB) {
                                comparison = -1;
                            }
                            return comparison;
                        }).map((item)=>{
                            return (
                                <ListingItem key={item.id}>
                                    <RoundCheckbox checked={item.checked} click={this.checkboxClick.bind(this,item)}/>
                                    <ListingItemLink click={this.editProduct.bind(this,item)}>
                                        <span className="typography typography--size1">{item.title}</span>
                                        <span className="typography typography--size4">EAN: {item.ean}</span>
                                    </ListingItemLink>
                                    <ListingItemRow>
                                        <PlusMinus change={this.updateQuantity.bind(this,item)} value={item.quantity} label="Ilość"/>
                                    </ListingItemRow>
                                </ListingItem>
                            )
                        })}
                    </Listing>
                </Container>

                <Footer>
                    <ActionLeft>
                        {DELETE_BTN}
                    </ActionLeft>
                    <ActionRight>
                        <RoundBtn icon="add" click={this.addNewProduct.bind(this)}/>
                    </ActionRight>
                </Footer>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
        selectedBoxID: state.selectedBoxID,
        selectedProductID: state.selectedProductID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        selectBoxID: (value) => selectBoxID(dispatch,value),
        selectProductID: (value) => selectProductID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);