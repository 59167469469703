

const defaultState = {
    //wybrany packinglist
    selectedPackingListID: null,
    //wybarny box
    selectedBoxID: null,
    //wybrany produkt
    selectedProductID: null
}

/**
 * zaznaczam wybrany packetlist
 * @param id
 * @param state
 * @returns {any}
 */
function selectPackingListID(id, state){
    const newState = Object.assign({},state);

    newState.selectedPackingListID = id;

    return newState;
}
/**
 * zaznaczam wybrany box
 * @param id
 * @param state
 * @returns {any}
 */
function selectBoxID(id, state){
    const newState = Object.assign({},state);

    newState.selectedBoxID = id;

    return newState;
}

/**
 * zaznaczam wybrany produkt
 * @param id
 * @param state
 * @returns {any}
 */
function selectProductID(id, state){
    const newState = Object.assign({},state);

    newState.selectedProductID = id;

    return newState;
}


export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SELECT_PACKINGLIST_ID':
            return selectPackingListID(action.item, state);
        case 'SELECT_BOX_ID':
            return selectBoxID(action.item, state);
        case 'SELECT_PRODUCT_ID':
            return selectProductID(action.item, state);
        default:
            return state
    }
};
