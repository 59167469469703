import BaseModel from './BaseModel';

export default class PackingListBox extends BaseModel{

    constructor(fields) {
        super(fields);

        // this.title = null;
        if(!this.products)
            this.products = [];
    }

}