import React, { Component } from 'react';

import Container from './../components/container';
import TopHeader from './../components/top-header';
import ActionLeft from './../components/actions-left';
import ActionRight from './../components/actions-right';
import TopHeaderBtn from './../components/buttons/top-header-btn';
import Footer from './../components/footer';
import RoundBtn from './../components/buttons/round-btn';
import TopHeaderTitle from './../components/text/top-header-title';
import FieldList from "./../components/fieldlist/fieldlist";
import Field from "./../components/fieldlist/field";

import DB from "./../LocalDb";
import PackingListBox from './../models/PackingListBox';
import { selectBoxID } from "./../redux/actions";

import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Loading from "./../components/loading";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: null,
            errorMsg: null,
            showLoading: false,
            boxItem: null
        }
    }

    componentDidMount() {
        if(this.props.selectedBoxID)
            this.loadData();
    }

    loadData(){
        this.setState({
                showLoading: true,
                checkedList: []
            },()=>
                DB.getPackingListBoxDetails(this.props.selectedPackingListID,this.props.selectedBoxID).then((item)=>{
                    this.setState({
                        value: item.title,
                        boxItem: item
                    })
                }).catch(()=>{
                    alert('Wystąpil błąd')
                }).finally(()=>{
                    this.setState({showLoading: false});
                })
        );
    }

    validateSave(){
        return new Promise((resolve, reject) => {

            if(!this.state.value){
                this.setState({errorMsg: 'Uzupełnij pole'});

                return reject();
            }

            const pattern = /^[a-zA-Z0-9\-\_\ ąćęłńóśżźĄĆĘŁŃÓŚŻŹ]{1,100}$/g;
            if(!pattern.test(this.state.value)){
                this.setState({errorMsg: 'Nieprawidłowe znaki. Minimalna długość to 2 znaki.'});
                return reject();
            }

            DB.isBoxNameAvailable(this.state.value,this.props.selectedPackingListID,this.props.selectedBoxID).then((value)=>{
                resolve();
            }).catch((err)=>{
                this.setState({errorMsg: 'Podana nazwa jest już zajęta.'});
                reject();
            })

        });

    }


    goBack(){
        this.props.showScreen('packingListDetails');
    }

    saveBtn(){

        this.setState({showLoading: true});
        this.validateSave().then((value)=>{
            let model;
            //edycja
            if(this.props.selectedPackingListID && this.props.selectedBoxID){
                model = new PackingListBox(this.state.boxItem);
                model.title = this.state.value;
                model.newUpdateDate();
            } else { // dodaj
                model = new PackingListBox({title: this.state.value});
            }

            DB.savePackingListBox(model,this.props.selectedPackingListID,this.props.selectedBoxID).then((value) => {
                // this.props.showScreen('packingListDetails');
                this.props.selectBoxID(model.id);
                this.props.showScreen('boxDetails');
            }).catch((err) => {
                alert('bład komunikacji');
                console.log(err);
            });

        }).catch((reason)=>{

        }).finally(()=>this.setState({showLoading: false}));

    }

    setBoxName(item){
        this.setState({
            value: item.target.value,
            errorMsg: null
        });
    }

    renderLoading(){
        if(this.state.showLoading)
            return <Loading/>;
        return '';
    }

    render(){

        const LOADING = this.renderLoading();
        const TITLE = this.props.selectedBoxID?"Edycja nazwy kartonu":"Nowy karton";

        return (
            <React.Fragment>
                {LOADING}
                <TopHeader>
                    <ActionLeft>
                        <TopHeaderBtn icon="back" click={this.goBack.bind(this)} />
                        <TopHeaderTitle>{TITLE}</TopHeaderTitle>
                    </ActionLeft>
                </TopHeader>

                <Container role="main">
                    <FieldList>
                        <Field label="Nazwa kartonu"
                               value={this.state.value}
                               change={this.setBoxName.bind(this)}
                               errorMsg={this.state.errorMsg}
                               maxlength="100" />
                    </FieldList>
                </Container>

                <Footer>
                    <ActionRight>
                        <RoundBtn icon="save" click={this.saveBtn.bind(this)}/>
                    </ActionRight>
                </Footer>

            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
        selectedBoxID: state.selectedBoxID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        selectBoxID: (value) => selectBoxID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);