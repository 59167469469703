import React, { Component } from 'react';
import Quagga from 'quagga';

class App extends Component {

    // initCamera(){
    //
    //     let deviceIdx = 0;
    //     let video = document.getElementById("video");
    //     const devices = await window.navigator.mediaDevices.enumerateDevices();
    //     let videoDevices = devices.filter(d => d.kind === "videoinput");
    //
    //     let mediaStream = await window.navigator.mediaDevices.getUserMedia({
    //         video: { deviceId: { exact: videoDevices[deviceIdx].deviceId } }
    //     });
    //
    //     video.srcObject = mediaStream;
    //     video.play();
    // }

    async componentDidMount() {
        let deviceIdx = 1;
        let video = document.getElementById("video");
        const devices = await window.navigator.mediaDevices.enumerateDevices();
        let videoDevices = devices.filter(d => d.kind === "videoinput");

        let mediaStream = await window.navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: videoDevices[deviceIdx].deviceId } }
        });

        video.srcObject = mediaStream;
        video.play();


        Quagga.init({
            inputStream : {
                name : "Live",
                type : "LiveStream",
                target: document.querySelector('#video')    // Or '#yourElement' (optional)
            },
            numOfWorkers: 1,
            locate: true,
            decoder : {
                readers : ['ean_reader', 'ean_8_reader', 'upc_reader', 'code_128_reader']
            }
        }, function(err) {
            if (err) {
                console.log(err);
                return
            }
            // alert("Initialization finished. Ready to start");
            Quagga.start();
        });
        Quagga.onDetected(this.onDetected.bind(this));

    }


    componentWillUnmount() {
        Quagga.offDetected(this.onDetected.bind(this));
        Quagga.stop();
        console.log('unmount')
    }

    onDetected(result){
        // alert(result)
        // alert(result.codeResult.code)
        this.props.grab(result)
    }

    render() {


        const style = {
            width: "calc(100vw - 30px)", height: "350px", border: "1px solid black"
        }

        return <div className="camera">
            <video id="video" style={ style }></video>
            <p>Wyceluj aparatem na kod EAN. Program spróbuje zczytać kod w przeciągu paru sekund.</p>
            <button type="button" onClick={this.props.hide}>Anuluj</button>
        </div>;
    }
}

export default App;