import React from 'react';

function App(props) {

    return (
        <React.Fragment>
            <button type="button" onClick={props.click} className="listing__item-link">
                {props.children}
            </button>
        </React.Fragment>
    );
}

export default App;