import React from 'react';

function App(props) {

    function clickHandler(e){
        if(props.click)
            props.click(e)
    }

    function checked() {
        if(props.checked)
            return 'checked';
        return '';
    }

    const c = checked();

    return (
        <React.Fragment>
            <label className="round-checkbox">
                <input type="checkbox" onChange={clickHandler} checked={c} />
                <span className="round-checkbox__check">
                    <i className="fas fa-check"></i>
                </span>
            </label>
        </React.Fragment>
    );
}

export default App;