import React, { Component } from 'react';

import Container from './../components/container';
import TopHeader from './../components/top-header';
import ActionLeft from './../components/actions-left';
import ActionRight from './../components/actions-right';
import TopHeaderBtn from './../components/buttons/top-header-btn';
import Footer from './../components/footer';
import RoundBtn from './../components/buttons/round-btn';
import TopHeaderTitle from './../components/text/top-header-title';
import FieldList from "./../components/fieldlist/fieldlist";
import Field from "./../components/fieldlist/field";
import FieldCamera from "./../components/fieldlist/field-camera";
import Dropdown from "../components/fieldlist/dropdown-box";
import Loading from "./../components/loading";

import DB from "./../LocalDb";

import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ProductModel from "../models/ProductModel";


const axios = require('axios');
const qs = require('querystring');


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ean: null,
            quantity: null,
            weight: null,
            title: '',
            box: null,
            errorMsgEAN: null,
            errorMsgQuantity: null,
            errorMsgWeight: null,
            errorMsgBox: null,
            showLoading: false,
            showTitleLoading: false,
            currentBox: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){
        this.setState({
                showLoading: true,
                checkedList: []
            },()=>
                DB.getPackingListBoxDetails(this.props.selectedPackingListID,this.props.selectedBoxID).then((item)=>{

                    const state = {
                        currentBox: {
                            value: item.id,
                            label: item.title
                        }
                    }

                    if(this.props.selectedProductID){
                        for(let index in item.products){
                            if(item.products[index].id === this.props.selectedProductID){
                                state.ean = item.products[index].ean;
                                state.quantity = item.products[index].quantity;
                                state.weight = item.products[index].weight;
                                state.title = item.products[index].title;
                            }
                        }
                    }

                    this.setState(state);
                }).catch(()=>{
                    alert('Wystąpil błąd')
                }).finally(()=>{
                        this.setState({showLoading: false});
                })
        );
    }

    setQuantity(item){
        this.setState({
            quantity: item.target.value,
            errorMsgQuantity: null
        });
    }

    setWeight(item){
        this.setState({
            weight: item.target.value,
            errorMsgWeight: null
        });
    }

    setEAN(item){
        this.setState({
            ean: item.target.value,
            showTitleLoading: true,
            errorMsgEAN: null
        },()=>{

            axios.post('https://e-outletkosmetyki.pl/rest/ean', qs.stringify({
                ean: this.state.ean
            }),{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response)=>{
                if(response.data.ean)
                    this.setState({
                        title: response.data.name,
                        showTitleLoading: false
                    });
            }).catch((error)=>{
                console.log(error);
                this.setState({
                    showTitleLoading: false,
                    title: ''
                });
            });

        });
    }

    selectBox(item){
        this.setState({
            currentBox: item
        });
    }

    validateSave(){
        return new Promise((resolve, reject) => {


            resolve();
        });

    }

    goBack(){
        this.props.showScreen('boxDetails');
    }

    saveBtn(){
        // this.props.showScreen('boxDetails');
        this.setState({
            showLoading: true
        }, () => {

            const fields = {
                ean: this.state.ean,
                weight: this.state.weight,
                title: this.state.title,
                quantity: this.state.quantity,
            };

            let productID = null;

            if(this.props.selectedProductID){
                fields.id = this.props.selectedProductID;
                productID = this.props.selectedProductID;
            }

            const model = new ProductModel(fields);

            DB.saveProduct(model,this.props.selectedPackingListID,this.state.currentBox.value,productID).then(()=>{
                this.props.showScreen('boxDetails');
            }).catch((err)=>{
                console.log(err);
            });

        });

    }

    renderLoading(){
        if(this.state.showLoading)
            return <Loading/>;
        return '';
    }

    renderTitleRender(){
        if(!this.state.showTitleLoading)
            return '';

        return (
            <div className="static-field__loading">
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }

    render(){
        const LOADING = this.renderLoading();
        const TITLE_LOADING = this.renderTitleRender();
        const TITLE = this.props.selectedProductID?"Edycja produktu":"Dodaj produkt";


        return (
            <React.Fragment>
                {LOADING}
                <TopHeader>
                    <ActionLeft>
                        <TopHeaderBtn icon="back" click={this.goBack.bind(this)} />
                        <TopHeaderTitle>{TITLE}</TopHeaderTitle>
                    </ActionLeft>
                </TopHeader>

                <Container role="main">
                    <FieldList>
                        <FieldCamera label="EAN"
                                     type="number"
                                     value={this.state.ean}
                                     change={this.setEAN.bind(this)}
                                     errorMsg={this.state.errorMsgEAN} />


                        <div className="static-field">
                            <div className="static-field__label" >Nazwa produktu</div>
                            <div className="static-field__value">{this.state.title}</div>
                            {TITLE_LOADING}
                        </div>

                        <Field label="Ilość"
                               value={this.state.quantity}
                               change={this.setQuantity.bind(this)}
                               errorMsg={this.state.errorMsgQuantity}
                               type="number" />
                        <Field label="Waga"
                               value={this.state.weight}
                               change={this.setWeight.bind(this)}
                               errorMsg={this.state.errorMsgWeight}
                               type="number" />
                        {
                            this.state.currentBox?<Dropdown change={this.selectBox.bind(this)} label="Karton" boxItem={this.state.currentBox}/>:""
                        }

                    </FieldList>
                </Container>

                <Footer>
                    <ActionRight>
                        <RoundBtn icon="save" click={this.saveBtn.bind(this)}/>
                    </ActionRight>
                </Footer>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
        selectedBoxID: state.selectedBoxID,
        selectedProductID: state.selectedProductID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // selectPackingListID: (value) => selectPackingListID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);