import React, { Component } from 'react';


import Container from './../components/container';
import TopHeader from './../components/top-header';
import ActionLeft from './../components/actions-left';
import ActionRight from './../components/actions-right';
import Footer from './../components/footer';
import RoundBtn from './../components/buttons/round-btn';

import Listing from './../components/listing/listing';
import ListingItem from './../components/listing/listing-item';
import ListingItemLink from './../components/listing/listing-item-link';
import RoundCheckbox from './../components/listing/round-checkbox';

import Loading from './../components/loading';
import DB from './../LocalDb';

import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { selectPackingListID, selectBoxID, selectProductID } from "./../redux/actions";


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkedList: [],
            packingList: [],
            showLoading: false
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){
        this.props.selectPackingListID(null);
        this.props.selectBoxID(null);
        this.props.selectProductID(null);

        this.setState({
            showLoading: true,
            checkedList: []
        },()=>
            DB.getPackingList().then((items)=>{
                this.setState({
                    packingList: items
                })
            }).catch(()=>{
                alert('Wystąpil błąd')
            }).finally(()=>{
                this.setState({showLoading: false});
            })
        );
    }

    checkboxClick(item){
        const list = this.state.packingList;
        const checkedList = this.state.checkedList;

        for (let index in list){
            if(list[index].id === item.id){
                list[index].checked = list[index].checked?false:true;

                const offset = checkedList.indexOf(item.id);
                //dodaj
                if(list[index].checked){
                    if(offset===-1){
                        checkedList.push(item.id);
                    }
                } else { // usun
                    if(offset!==-1){
                        checkedList.splice(offset)
                    }
                }
            }
        }

        this.setState({packingList: list, checkedList: checkedList});
    }

    deleteSelectedClick(){
        const list = this.state.packingList;
        const checkedList = this.state.checkedList;

        const newList = list.filter((item)=>{

            const offset = checkedList.indexOf(item.id);

            if(offset===-1)
                return item;
        });

        DB.deleteSelectedPackingList(checkedList,()=>{
            this.loadData();
        })
    }

    addNewPackingListBtn(){
        this.props.showScreen('addPackingList');
    }

    goToPackingListDetails(item){
        this.props.selectPackingListID(item.id);
        this.props.showScreen('packingListDetails');
    }

    renderDeleteBtn(){
        const checkedList = this.state.checkedList;
        if(!checkedList.length)
            return false;

        return (
            <React.Fragment>
                <RoundBtn icon="delete" click={this.deleteSelectedClick.bind(this)}/>
            </React.Fragment>
        )
    }

    renderLoading(){
        if(this.state.showLoading)
            return <Loading/>;
        return '';
    }

    render(){

        const DELETE_BTN = this.renderDeleteBtn();

        const LOADING = this.renderLoading();

        return (
            <React.Fragment>
                {LOADING}
                <TopHeader />

                <Container role="main">
                    <Listing>
                        {this.state.packingList.sort((a,b)=>{
                            const boxA = a.title.toUpperCase();
                            const boxB = b.title.toUpperCase();

                            let comparison = 0;
                            if (boxA > boxB) {
                                comparison = 1;
                            } else if (boxA < boxB) {
                                comparison = -1;
                            }
                            return comparison;
                        }).map((item)=>{
                            return (
                                <ListingItem key={item.id}>
                                    <RoundCheckbox checked={item.checked} click={this.checkboxClick.bind(this,item)}/>
                                    <ListingItemLink click={this.goToPackingListDetails.bind(this,item)}>
                                        <span className="typography typography--size1">{item.title}</span>
                                        <span className="typography typography--size4">{item.lastOperationDate()}</span>
                                    </ListingItemLink>
                                </ListingItem>
                            )
                        })}
                    </Listing>
                </Container>

                <Footer>
                    <ActionLeft>
                        {DELETE_BTN}
                    </ActionLeft>
                    <ActionRight>
                        <RoundBtn icon="add" click={this.addNewPackingListBtn.bind(this)}/>
                    </ActionRight>
                </Footer>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        selectPackingListID: (value) => selectPackingListID(dispatch,value),
        selectBoxID: (value) => selectBoxID(dispatch,value),
        selectProductID: (value) => selectProductID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);