import React from 'react';

function App(props) {

    let icon;
    const cssClass = ['btn-round'];

    switch (props.icon) {
        case 'add':
            icon = "fas fa-plus";
            cssClass.push('btn-round--green');
            break;
        case 'delete':
            icon = "fas fa-trash-alt";
            cssClass.push('btn-round--red');
            break;
        case 'save':
            icon = "far fa-save";
            cssClass.push('btn-round--green');
            break;
        default:
            icon = 'far fa-star';
    }

    function click() {
        if(props.click)
            props.click();
    }


    return (
        <React.Fragment>
            <button type="button" onClick={click} className={cssClass.join(' ')}><i className={icon}></i></button>
        </React.Fragment>
    );
}

export default App;