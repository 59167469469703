import moment from 'moment';

export default class BaseModel {

    constructor(fields) {

        this.assignDataToFields(fields);

        if(!this.updatedate)
            this.updatedate = new Date();

        if(!this.id){
            this.id = this.generateID();
        }
    }

    generateID(){
        return '_' + Math.random().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 9);
    }

    assignDataToFields(fields){

        for(let index in fields){
            // console.log(index,fields)
            this[index] = fields[index];
        }

    }

    /**
     * data w formacie 2000-01-01 23:23
     * @returns {string}
     */
    lastOperationDate(){
        return moment(this.updatedate).format('YYYY-MM-DD LT');
    }

    /**
     * nowa data ostatniej operacji
     */
    newUpdateDate(){
        this.updatedate = new Date();
    }

}