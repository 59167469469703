import React, { Component } from 'react';

import Container from './../components/container';
import TopHeader from './../components/top-header';
import ActionLeft from './../components/actions-left';
import ActionRight from './../components/actions-right';
import TopHeaderBtn from './../components/buttons/top-header-btn';
import Footer from './../components/footer';
import RoundBtn from './../components/buttons/round-btn';
import TopHeaderTitle from './../components/text/top-header-title';
import FieldList from "./../components/fieldlist/fieldlist";
import Field from "./../components/fieldlist/field";
import Loading from "./../components/loading";

import DB from "./../LocalDb";
import PackingListModel from './../models/PackingList';

import ReactDOM from "react-dom";
import { connect } from "react-redux";


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: null,
            errorMsg: null,
            showLoading: false,
            packingListItem: null
        }
    }


    componentDidMount() {
        if(this.props.selectedPackingListID)
            this.loadData();
    }

    loadData(){
        this.setState({
                showLoading: true,
                checkedList: []
            },()=>
                DB.getPackingListDetails(this.props.selectedPackingListID).then((item)=>{
                    this.setState({
                        value: item.title,
                        packingListItem: item
                    })
                }).catch(()=>{
                    alert('Wystąpil błąd')
                }).finally(()=>{
                    this.setState({showLoading: false});
                })
        );
    }

    validateSave(){
        return new Promise((resolve, reject) => {

            if(!this.state.value){
                this.setState({errorMsg: 'Uzupełnij pole'});

                return reject();
            }

            const pattern = /^[a-zA-Z0-9\-\_\ ąćęłńóśżźĄĆĘŁŃÓŚŻŹ]{2,100}$/g;
            if(!pattern.test(this.state.value)){
                this.setState({errorMsg: 'Nieprawidłowe znaki. Minimalna długość to 2 znaki.'});
                return reject();
            }

            DB.isPackingNameAvailable(this.state.value).then((value)=>{
                resolve();
            }).catch((err)=>{
                this.setState({errorMsg: 'Podana nazwa jest już zajęta.'});
                reject();
            })

        });

    }

    setPackingListName(item){
        this.setState({
            value: item.target.value,
            errorMsg: null
        });
    }

    goBack(){
        this.props.showScreen('packingList');
    }

    saveBtn(){
        this.setState({showLoading: true});
        this.validateSave().then((value)=>{
            let model;
            //edycja
            if(this.props.selectedPackingListID){
                model = new PackingListModel(this.state.packingListItem);
                model.title = this.state.value;
                model.newUpdateDate();
            } else { // dodaj
                model = new PackingListModel({title: this.state.value});
            }

            DB.createPackingList(model).then((value) => {
                this.props.showScreen('packingList');
            }).catch((err) => {
                alert('bład komunikacji');
                console.log(err);
            });

        }).catch((reason)=>{

        }).finally(()=>this.setState({showLoading: false}));
    }

    renderLoading(){
        if(this.state.showLoading)
            return <Loading/>;
        return '';
    }

    render(){
        const LOADING = this.renderLoading();
        const TITLE = this.props.selectedPackingListID?"Edycja nazwy packing listy":"Nowa packing lista";

        return (
            <React.Fragment>
                {LOADING}

                <TopHeader>
                    <ActionLeft>
                        <TopHeaderBtn icon="back" click={this.goBack.bind(this)} />
                        <TopHeaderTitle>{TITLE}</TopHeaderTitle>
                    </ActionLeft>
                </TopHeader>

                <Container role="main">
                    <FieldList>
                        <Field label="Nazwa listy"
                               value={this.state.value}
                               change={this.setPackingListName.bind(this)}
                               errorMsg={this.state.errorMsg}
                               maxlength="100" />
                    </FieldList>
                </Container>

                <Footer>
                    <ActionRight>
                        <RoundBtn icon="save" click={this.saveBtn.bind(this)}/>
                    </ActionRight>
                </Footer>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // selectPackingListID: (value) => selectPackingListID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);