import React, { Component } from 'react';

import Container from './../components/container';
import TopHeader from './../components/top-header';
import ActionLeft from './../components/actions-left';
import ActionRight from './../components/actions-right';
import Footer from './../components/footer';
import RoundBtn from './../components/buttons/round-btn';
import TopHeaderBtn from './../components/buttons/top-header-btn';
import TopHeaderTitle from './../components/text/top-header-title';

import Listing from './../components/listing/listing';
import ListingItem from './../components/listing/listing-item';
import ListingItemLink from './../components/listing/listing-item-link';
import RoundCheckbox from './../components/listing/round-checkbox';

import ExportBtn from './../components/buttons/export-packinglist-btn';

import Loading from './../components/loading';
import DB from './../LocalDb';

import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {selectPackingListID} from "../redux/actions";
import { selectBoxID, selectProductID } from "./../redux/actions";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkedList: [],
            packingList: [],
            currentPackingListTitle: '',
            showLoading: false
        }
    }


    componentDidMount() {
        this.loadData();
    }

    loadData(){
        this.props.selectBoxID(null);
        this.props.selectProductID(null);

        this.setState({
                showLoading: true,
                checkedList: []
            },()=>
                DB.getPackingListDetails(this.props.selectedPackingListID).then((item)=>{
                    this.setState({
                        packingList: item.boxes,
                        currentPackingListTitle: item.title
                    })
                }).catch(()=>{
                    alert('Wystąpil błąd')
                }).finally(()=>{
                    this.setState({showLoading: false});
                })
        );
    }

    checkboxClick(item){
        const list = this.state.packingList;
        const checkedList = this.state.checkedList;

        for (let index in list){
            if(list[index].id === item.id){
                list[index].checked = list[index].checked?false:true;

                const offset = checkedList.indexOf(item.id);
                //dodaj
                if(list[index].checked){
                    if(offset===-1){
                        checkedList.push(item.id);
                    }
                } else { // usun
                    if(offset!==-1){
                        checkedList.splice(offset)
                    }
                }
            }
        }

        this.setState({packingList: list, checkedList: checkedList});
    }

    deleteSelectedClick(){
        const list = this.state.packingList;
        const checkedList = this.state.checkedList;

        const newList = list.filter((item)=>{

            const offset = checkedList.indexOf(item.id);

            if(offset===-1)
                return item;
        });

        DB.deleteSelectedBoxes(checkedList,this.props.selectedPackingListID,()=>{
            this.loadData();
        });
    }

    goBack(){
        this.props.showScreen('packingList');
    }

    goToEditScreen(){
        this.props.showScreen('addPackingList');
    }

    goToBoxDetails(item){
        this.props.selectBoxID(item.id);
        this.props.showScreen('boxDetails');
    }

    addNewPackingListDetailsBtn(item){
        this.props.showScreen('addPackingListDetails');
    }

    renderDeleteBtn(){
        const checkedList = this.state.checkedList;
        if(!checkedList.length)
            return false;

        return (
            <React.Fragment>
                <RoundBtn icon="delete" click={this.deleteSelectedClick.bind(this)}/>
            </React.Fragment>
        )
    }


    renderLoading(){
        if(this.state.showLoading)
            return <Loading/>;
        return '';
    }

    render(){

        const DELETE_BTN = this.renderDeleteBtn();

        const LOADING = this.renderLoading();

        const numbers = this.state.packingList.filter((item)=>{
            return !isNaN(item.title)
        });
        const strings = this.state.packingList.filter((item)=>{
            return isNaN(item.title)
        });


        return (
            <React.Fragment>
                {LOADING}
                <TopHeader>
                    <ActionLeft>
                        <TopHeaderBtn icon="back" click={this.goBack.bind(this)} />
                        <TopHeaderTitle>{this.state.currentPackingListTitle}</TopHeaderTitle>
                    </ActionLeft>
                    <ActionRight>
                        <TopHeaderBtn icon="edit" click={this.goToEditScreen.bind(this)} />
                    </ActionRight>
                </TopHeader>

                <Container role="main">
                    <Listing>
                        {numbers.sort((a,b)=>{
                            const boxA = parseInt(a.title);
                            const boxB = parseInt(b.title);

                            let comparison = 0;
                            if (boxA > boxB) {
                                comparison = 1;
                            } else if (boxA < boxB) {
                                comparison = -1;
                            }
                            return comparison;
                        }).map((item)=>{
                            return (
                                <ListingItem key={item.id}>
                                    <RoundCheckbox checked={item.checked} click={this.checkboxClick.bind(this,item)}/>
                                    <ListingItemLink click={this.goToBoxDetails.bind(this,item)}>
                                        <span className="typography typography--size1">{item.title}</span>
                                        <span className="typography typography--size4">{item.lastOperationDate()}</span>
                                    </ListingItemLink>
                                </ListingItem>
                            )
                        })}
                        {strings.sort((a,b)=>{
                            const boxA = a.title.toUpperCase();
                            const boxB = b.title.toUpperCase();

                            return a.title.localeCompare(b.title);

                            let comparison = 0;
                            if (boxA > boxB) {
                                comparison = 1;
                            } else if (boxA < boxB) {
                                comparison = -1;
                            }
                            return comparison;
                        }).map((item)=>{
                            return (
                                <ListingItem key={item.id}>
                                    <RoundCheckbox checked={item.checked} click={this.checkboxClick.bind(this,item)}/>
                                    <ListingItemLink click={this.goToBoxDetails.bind(this,item)}>
                                        <span className="typography typography--size1">{item.title}</span>
                                        <span className="typography typography--size4">{item.lastOperationDate()}</span>
                                    </ListingItemLink>
                                </ListingItem>
                            )
                        })}
                    </Listing>
                </Container>

                <Footer>
                    <ActionLeft>
                        {DELETE_BTN}
                        <ExportBtn data={this.state.packingList} />
                    </ActionLeft>
                    <ActionRight>
                        <RoundBtn icon="add" click={this.addNewPackingListDetailsBtn.bind(this)}/>
                    </ActionRight>
                </Footer>

            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        selectBoxID: (value) => selectBoxID(dispatch,value),
        selectProductID: (value) => selectProductID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);