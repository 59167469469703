import React, { Component } from 'react';
import Camera from '../camera';

class App extends Component {


    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            showCamera: false
        }
    }


    renderError() {
        if (!this.props.errorMsg)
            return '';

        return (
            <div className="field__error animated shake">{this.props.errorMsg}</div>
        );
    }

    showCamera() {
        this.setState({
            showCamera: true
        });
    }

    hideCamera() {
        this.setState({
            showCamera: false
        });
    }

    grabEAN(ean){
        // alert(ean.codeResult.code)
        // alert(JSON.stringify(ean))

        this.setState({
            showCamera: false
        });

        this.props.change({
            target:{
                value: ean.codeResult.code
            }
        })
    }

    render() {
        let type;

        switch (this.props.type) {
            case "number":
            case "email":
            case "password":
                type = this.props.type;
                break;
            default:
                type = "text";
        }

        const errorMsg = this.renderError();

        const CAMERA = this.state.showCamera?<Camera grab={this.grabEAN.bind(this)} hide={this.hideCamera.bind(this)} />:'';

        return (
            <React.Fragment>
                {CAMERA}
                <label className="field field--camera">
                    <span className="field__label">{this.props.label}</span>
                    <input
                        id="ean"
                        className="field__input"
                        type={type}
                        value={this.props.value ? this.props.value : ''}
                        onChange={this.props.change}
                        maxLength={this.props.maxlength}/>
                    <button onClick={this.showCamera.bind(this)} type="button" className="field__btn"><i className="fas fa-camera"></i>
                    </button>
                    {errorMsg}
                </label>
            </React.Fragment>
        );
    }
}

export default App;