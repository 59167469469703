import BaseModel from './BaseModel';

export default class PackingList extends BaseModel{

    constructor(fields) {
        super(fields);

        // this.title = null;
        if(!this.boxes)
            this.boxes = [];
    }

}