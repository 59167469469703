import BaseModel from './BaseModel';

export default class ProductModel extends BaseModel{

    constructor(fields) {
        super(fields);

        if(!this.title)
            this.title = "";

        if(!this.quantity)
            this.quantity = 0;

        if(!this.weight)
            this.weight = 0;

        if(!this.ean)
            this.ean = 0;
    }

}