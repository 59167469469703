import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import DB from './../../LocalDb';
import Select from 'react-select';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            boxes: [],
            selectedOption: props.boxItem?props.boxItem:null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){

        this.setState({
                checkedList: []
            },()=>
                DB.getPackingListDetails(this.props.selectedPackingListID).then((item)=>{
                    this.setState({
                        boxes: item.boxes,
                    })
                }).catch(()=>{
                    alert('Wystąpil błąd')
                }).finally(()=>{
                    this.setState({showLoading: false});
                })
        );
    }

    handleChange(e){
        this.props.change(e)
        this.setState({
            selectedOption: e
        });
    }

    render() {

        const options = this.state.boxes.map((item)=>{
           return {
               label: item.title,
               value: item.id
           }
        });

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'green' : 'black',
                backgroundColor: state.isSelected ? '#f6f6f6' : 'white',
                padding: 5,
            }),
            indicatorsContainer: ()=>({
               display: 'none'
            }),
            control: () => ({
                // none of react-select's styles are passed to <Control />
                width: "100%",
                display: "flex"
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { ...provided, opacity, transition };
            }
        }

        return (
            <React.Fragment>
                <label className="dropdown">
                    <span className="dropdown__label">{this.props.label}</span>

                    <Select className="dropdown__select"
                            options={options}
                            styles={customStyles}
                            onChange={this.handleChange.bind(this)}
                            placeholder="Wybierz"
                            value={this.state.selectedOption}
                    />

                    <span className="dropdown__line"></span>
                </label>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
        selectedBoxID: state.selectedBoxID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // selectPackingListID: (value) => selectPackingListID(dispatch,value),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);