import React from 'react';

function App(props) {

    return (
        <React.Fragment>
            <div className="top-header__title">{props.children}</div>
        </React.Fragment>
    );
}

export default App;