import React, { Component } from 'react';

class App extends Component {


    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
        }
    }

    incrementValue(){
        let newValue = this.state.value;

        newValue++;
        if(newValue>1000)
            newValue = 1000;

        this.setState({
            value: newValue
        });
        this.props.change(newValue);
    }

    decrementValue(){
        let newValue = this.state.value;
        newValue--;

        if(newValue<0)
            newValue = 0;

        this.setState({
            value: newValue
        });
        this.props.change(newValue);
    }

    changeValue(e){
        let newValue = e.target.value;
        if(newValue>1000) newValue = 1000;
        if(newValue<0) newValue = 0;

        this.setState({
            value: newValue
        });

        this.props.change(newValue);
    }

    render() {
        return (
            <React.Fragment>
                <label className="plus-minus">
                    <span className="plus-minus__label">{this.props.label}</span>
                    <input className="plus-minus__input" type="number" value={this.state.value} onChange={this.changeValue.bind(this)}/>
                    <span className="plus-minus__actions">
                    <button type="button" className="plus-minus__btn" onClick={this.decrementValue.bind(this)}><i className="fas fa-minus"></i></button>
                    <button type="button" className="plus-minus__btn" onClick={this.incrementValue.bind(this)}><i className="fas fa-plus"></i></button>
                </span>
                </label>
            </React.Fragment>
        );
    }
}

export default App;