import React from 'react';

function App(props) {

    let type;

    switch (props.type) {
        case "number":
        case "email":
        case "password":
            type = props.type;
            break;
        default:
            type = "text";
    }

    function renderError() {
        if(!props.errorMsg)
            return '';

        return (
            <div className="field__error animated shake">{props.errorMsg}</div>
        );
    }

    const errorMsg = renderError();

    return (
        <React.Fragment>
            <label className="field">
                <span className="field__label" >{props.label}</span>
                <input
                    className="field__input"
                    value={props.value?props.value:''}
                    type={type}
                    onChange={props.change}
                    maxLength={props.maxlength}/>
                {errorMsg}
            </label>
        </React.Fragment>
    );
}

export default App;