import React from 'react';

function App(props) {

    const cssClass = ['container'];
    if(props.role && props.role==="main")
        cssClass.push('container--main');

    return (
        <React.Fragment>
            <div className={cssClass.join(' ')}>{props.children}</div>
        </React.Fragment>
    );
}

export default App;