import React from 'react';

function App(props) {

    return (
        <React.Fragment>
            <div className="listing__item-row">
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default App;