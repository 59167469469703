import React from 'react';

function App(props) {

    let icon;

    switch (props.icon) {
        case 'back':
            icon = "fas fa-chevron-left";
            break;
        case 'edit':
            icon = "fas fa-edit";
            break;
        default:
            icon = 'far fa-star';
    }

    function click() {
        if(props.click)
            props.click();
    }

    return (
        <React.Fragment>
            <button type="button" onClick={click} className="top-header__btn"><i className={icon}></i></button>
        </React.Fragment>
    );
}

export default App;