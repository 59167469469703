import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';

//check for support
if (!('indexedDB' in window)) {
    console.log('This browser doesn\'t support IndexedDB');
}
else {
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}