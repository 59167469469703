import React, { Component } from 'react';
import Excel from './../excel';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggled: false
        }
    }

    click() {
        this.setState({
            toggled: !this.state.toggled
        })
    }


    render() {

        const cssClass = ['dropupmenu-round'];
        if(this.state.toggled)
            cssClass.push('dropupmenu-round--toggled');

        return (
            <React.Fragment>
                <div className={cssClass.join(' ')}>
                    <button type="button" onClick={this.click.bind(this)} className="btn-round btn-round--black-border"><i
                        className="fas fa-share-square"></i></button>
                    <div className="dropupmenu-round__items">
                        <Excel data={this.props.data} click={this.click.bind(this)}/>
                        {/*<button onClick={this.click.bind(this)} type="button" className="dropupmenu-round__item">Eksport CSV</button>*/}
                        <button onClick={this.click.bind(this)} type="button" className="dropupmenu-round__item">Eksport E-mail</button>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default App;