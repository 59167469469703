import localforage from "localforage";
import PackingListModel from './models/PackingList';
import PackingListBoxModel from "./models/PackingListBox";
import ProductModel from "./models/ProductModel";
import { store } from './redux/store';

localforage.config({
    driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name        : 'beautygallery ean scaner',
    version     : 0.1,
    storeName   : 'beautygallery', // Should be alphanumeric, with underscores.
    description : 'baza z lista skanów'
});

class App {

    /**
     * utworz nowy packing list
     * @param PackingListModel item
     * @returns {Promise<*>}
     */
    createPackingList(item){
        return localforage.setItem(item.id,item);
    }

    /**
     * Sprawdza czy podna nazwa moze byc nazwa packing list
     * @param String name
     * @returns {Promise<unknown>}
     */
    isPackingNameAvailable(name){
        let isAvailable = true;

        return new Promise((resolve, reject) => {
            localforage.iterate(function(value, key, iterationNumber) {
                //edycja
                if(store.getState().selectedPackingListID){
                    if(value.title === name && value.id != store.getState().selectedPackingListID)
                        isAvailable = false;
                }
                //dodaj nowy
                else if(value.title === name)
                    isAvailable = false;

            }).then(function() {

                if(isAvailable)
                    resolve();
                else
                    reject();

            }).catch(function(err) {

                console.log(err);
                reject();
            });
        });
    }

    /**
     * Lista packing list
     * @returns {Promise<unknown>}
     */
    getPackingList(){
        const output = [];

        return new Promise((resolve, reject) => {
            localforage.iterate(function(value, key, iterationNumber) {
                output.push(new PackingListModel(value));
            }).then(function() {
                resolve(output);
            }).catch(function(err) {

                console.log(err);
                reject();
            });
        });
    }

    /**
     * usun zaznaczone pakiety
     * @param ids
     * @param callback
     */
    deleteSelectedPackingList(ids=[],callback){

        let operationCounter = 0;
        for(let i = 0; i<ids.length; i++){

            const subPromise = new Promise((subResolve, subReject) => {
                localforage.removeItem(ids[i]).then(function() {
                    subResolve();
                }).catch(function(err) {
                    console.log(err);
                    subReject();
                });
            }).then(()=>{

            }).catch(()=>{

            }).finally(()=>{
                operationCounter++;

                if(operationCounter == ids.length){
                    callback();
                }
            });
        }

    }

    /**
     * pobiera liste boksow w packing lisice
     * @param id
     * @returns {Promise<unknown>}
     */
    getPackingListDetails(id){
        return new Promise((resolve, reject) =>{
            localforage.getItem(id).then((value)=>{
                value = new PackingListModel(value);
                if(value.boxes){
                    for(let index in value.boxes){
                        value.boxes[index] = new PackingListBoxModel(value.boxes[index]);
                    }
                }

                resolve(value);
            }).catch((err)=>{
                reject();
            });
        });
    }


    /**
     * czy nazwa boksa juz wystepuje ?
     * @param name
     * @param packinglist_id
     * @returns {Promise<unknown>}
     */
    isBoxNameAvailable(name,packinglist_id,box_id){
        let isAvailable = true;

        return new Promise((resolve, reject) => {

            localforage.getItem(packinglist_id).then((item)=>{

                if(!item.boxes){
                    resolve();
                } else if(item.length === 0) {
                    resolve();
                } else {

                    for(let index in item.boxes){

                        if(box_id){

                            if(box_id !== item.boxes[index].id && item.boxes[index].title === name)
                                isAvailable = false;

                        } else {
                            if (item.boxes[index].title === name)
                                isAvailable = false;
                        }
                    }

                    if(isAvailable)
                        resolve();
                    else
                        reject();
                }

            }).catch((err)=>{
                console.log(err);
                reject();
            });

        });
    }

    /**
     * zapisuje nazwe boksa
     * @param model
     * @param packinglist_id
     * @returns {Promise<unknown>}
     */
    savePackingListBox(model,packinglist_id,box_id){

        return new Promise((resolve, reject) => {

            localforage.getItem(packinglist_id).then((item)=>{
                item = new PackingListModel(item);
                //edycja
                if(box_id){
                    for(let index in item.boxes){
                        if(item.boxes[index].id === box_id){
                            item.boxes[index] = model;
                        }
                    }
                } else { //dodaj
                    item.boxes.push(model);
                }
                item.newUpdateDate();

                localforage.setItem(packinglist_id,item).then((value)=>{

                    resolve();

                }).catch((err)=>{
                    console.log(err);
                    reject();
                })

            }).catch((err)=>{
                console.log(err);
                reject();
            });

        });

    }

    /**
     * usuwa zanaczone boksy
     * @param ids
     * @param packingListID
     * @param callback
     */
    deleteSelectedBoxes(ids=[],packingListID,callback){

        localforage.getItem(packingListID).then((value)=>{
            value = new PackingListModel(value);

            const boxes = value.boxes;
            console.log(ids)

            for (let i=0; i<boxes.length; i++){
                let index = ids.indexOf(boxes[i].id);
                //jest
                if(index != -1){
                    boxes.splice(i,1);
                    i=0;
                }
            }

            value.boxes = boxes;
            value = new PackingListModel(value);
            value.newUpdateDate();

            localforage.setItem(packingListID,value).then(()=>{
                callback()
            }).catch((err)=>{
              console.log(err)
            })

        }).catch((err)=>{
            console.log(err)
        });

    }


    getPackingListBoxDetails(id,boxID){
        return new Promise((resolve, reject) =>{
            localforage.getItem(id).then((value)=>{
                // value = new PackingListModel(value);
                let products = [];
                let box;

                if(value.boxes){
                    for(let index in value.boxes){
                        // value.boxes[index] = new PackingListBoxModel(value.boxes[index]);
                        if(value.boxes[index].id === boxID){
                            products = value.boxes[index].products;
                            box = value.boxes[index];
                            break;
                        }
                    }
                }

                for(let index in products){
                    products[index] = new ProductModel(products[index]);
                }
                box = new PackingListBoxModel(box);
                box.products = products;

                resolve(box);
            }).catch((err)=>{
                reject();
            });
        });
    }

    saveProduct(product,packingListID,boxID,productID=null){

        return new Promise((resolve, reject) => {

            localforage.getItem(packingListID).then((item)=>{
                item = new PackingListModel(item);
                item.newUpdateDate();

                let box = null;

                if(productID){
                    for(let index in item.boxes){
                        for(let productIndex in item.boxes[index].products){
                            //czy znajduje sie w innych boxach ?
                            if(item.boxes[index].products[productIndex].id == productID){
                                item.boxes[index].products.splice(productIndex,1);
                            }
                        }
                    }
                }


                for(let index in item.boxes){
                    if(item.boxes[index].id === boxID){
                        box = item.boxes[index];
                        box.products.push(new ProductModel(product));
                        box = new PackingListBoxModel(box);
                        box.newUpdateDate();

                        item.boxes[index] = box;
                    }
                }


                localforage.setItem(packingListID,item).then((value)=>{

                    resolve();

                }).catch((err)=>{
                    console.log(err);
                    reject();
                })

            }).catch((err)=>{
                console.log(err);
                reject();
            });

        });

    }


    deleteSelectedProducts(ids=[],packingListID, boxID,callback){

        localforage.getItem(packingListID).then((item)=>{
            item = new PackingListModel(item);
            let box = null;

            for(let index in item.boxes){
                if(item.boxes[index].id === boxID){
                    box = item.boxes[index];
                    box = new PackingListBoxModel(box);
                    box.newUpdateDate();

                    const newProducts = [];

                    for (let i=0; i< box.products.length; i++){
                        let offset = ids.indexOf(box.products[i].id);
                        //jest
                        if(offset === -1){
                            newProducts.push(box.products[i])
                        }
                    }
                    box.products = newProducts;

                    item.boxes[index] = box;
                }
            }
            item.newUpdateDate();

            localforage.setItem(packingListID,item).then(()=>{
                callback()
            }).catch((err)=>{
                console.log(err)
            })

        }).catch((err)=>{
            console.log(err)
        });

    }

}

export default new App;