/**
 * zaznacz wybrany packing list
 * @param dispatch
 * @param item
 */
export const selectPackingListID = (dispatch, item) => {
    dispatch({
        type: 'SELECT_PACKINGLIST_ID',
        item
    });
}

/**
 * zaznacz wybrany box
 * @param dispatch
 * @param item
 */
export const selectBoxID = (dispatch, item) => {
    dispatch({
        type: 'SELECT_BOX_ID',
        item
    });
}

/**
 * zaznacz wybrany produkt
 * @param dispatch
 * @param item
 */
export const selectProductID = (dispatch, item) => {
    dispatch({
        type: 'SELECT_PRODUCT_ID',
        item
    });
}
