import React, { Component } from 'react';
// import Camera from './components/camera';
import PackingList from './screens/PackingList';
import AddPackingList from './screens/AddPackingList';
import PackingListDetails from './screens/PackingListDetails';
import AddPackingListDetails from './screens/AddPackingListDetails';
import BoxDetails from './screens/BoxDetails';
import AddProduct from './screens/AddProduct';

import './css/main.css'

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            screen: null
        }
    }

    setScreen(screen){
        this.setState({
            screen: screen
        });
    }

    showScreen(screen){
        switch(screen){
            // case "camera":
            //     return <Camera showScreen={this.setScreen.bind(this)}/>;
            case "addProduct":
                return <AddProduct showScreen={this.setScreen.bind(this)}/>;
            case "boxDetails":
                return <BoxDetails showScreen={this.setScreen.bind(this)}/>;
            case "packingListDetails":
                return <PackingListDetails showScreen={this.setScreen.bind(this)}/>;
            case "addPackingListDetails":
                return <AddPackingListDetails showScreen={this.setScreen.bind(this)}/>;
            case "addPackingList":
                return <AddPackingList showScreen={this.setScreen.bind(this)}/>;
            default:
                return <PackingList showScreen={this.setScreen.bind(this)}/>;
        }
    }

    render() {
        const SCREEN = this.showScreen(this.state.screen);
        return (
            <React.Fragment>

                {SCREEN}

            </React.Fragment>
        );
    }
}

export default App;
