import React from 'react';

function App(props) {

    return (
        <React.Fragment>
            <div className="footer">
                <div className="container">
                    <div className="actions">
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default App;