import React, { Component } from 'react';
import ReactExport from "react-export-excel";

import ReactDOM from "react-dom";
import { connect } from "react-redux";
import DB from "../LocalDb";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class Download extends Component {


    constructor(props) {
        super(props);

        this.state = {
        }
    }

    loadData(){
        const output = [];
        const boxes = this.props.data;

        for(let index in boxes){
            for(let i in boxes[index].products){
                output.push({
                    box: boxes[index].title,
                    ean: boxes[index].products[i].ean,
                    title: boxes[index].products[i].title,
                    quantity: parseInt(boxes[index].products[i].quantity),
                    weight: parseFloat(boxes[index].products[i].weight)
                })
            }
        }
        return output;
    }

    render() {

        const dataSet = this.loadData();

        return (
            <ExcelFile element={<button onClick={this.props.click} type="button" className="dropupmenu-round__item">Eksport EXCEL</button>}>
                <ExcelSheet data={dataSet} name="packinglist">
                    <ExcelColumn label="BOX" value="box"/>
                    <ExcelColumn label="EAN" value="ean"/>
                    <ExcelColumn label="Product" value="title"/>
                    <ExcelColumn label="Description" value="ean"/>
                    <ExcelColumn label="QTY pcs Status" value="quantity"/>
                    <ExcelColumn label="weight" value="weight" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        selectedPackingListID: state.selectedPackingListID,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);