import React from 'react';

function App(props) {

    return (
        <React.Fragment>
            <div className="top-header">
                <div className="actions">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
}

export default App;